<script>
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "GamePreview"
})
export default class GamePreview extends Vue {
}
</script>
<template>
    <div class="GamePreview">
        <div class="title">TEST NOW OUR DEMO</div>
        <iframe :src="`/gpsol2`" width="100%" height="650px" />
    </div>
</template>
<style lang="scss">
.GamePreview {

    .title {
        font-family: 'Press Start 2P', cursive !important;
        color: $black;
        font-size: 42px;
        font-weight: 300;
    }
}
</style>
