
import {Component, Prop, Vue} from 'vue-property-decorator';
import TopBar from "@/components/TopBar.vue";
import TopPage from "@/components/TopPage.vue";
import Description from "@/components/Description.vue";
import Rarity from "@/components/Rarity.vue";
import Roadmap from "@/components/Roadmap.vue";
import Faq from "@/components/Faq.vue";
import GPCFooter from "@/components/GPCFooter.vue";
import Team from "@/components/Team.vue";
import InDevelopment from "@/components/InDevelopment.vue";
import Carousel from "@/components/Carousel.vue";
import Preview from "@/components/Preview.vue";

@Component({
    components: {Preview, Carousel, InDevelopment, Team, GPCFooter, Faq, Roadmap, Rarity, Description, TopPage}
})
export default class Home extends Vue {
    @Prop() race!: boolean;

    displayable = false;

    goTop() {
        this.$scrollTo(document.body, 700);
    }

    beforeMount() {
        window.addEventListener('scroll', () => {
             this.displayable = window.scrollY > (window.innerHeight - 500);
        });
    }
}
