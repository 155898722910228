
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "Faq"
})
export default class Faq extends Vue {
    opened:number[] = [];

    open(index:number) {
        let val = !this.opened[index];
        this.opened = [];
        this.$set(this.opened, index, val);
    }
}
