
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "Carousel"
})
export default class Carousel extends Vue {

    imgs:string[] = [];

    getRandomInt(min:number, max:number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    beforeMount() {
        for (let i = 0, len = 30; i < len; ++i) {
            // @ts-ignore;
            let img:string = "https://bafybeihfdgf4ehdq5kgansjcgscodnjcszpeyex7trlpapchimyi2fy2p4.ipfs.nftstorage.link/" + this.getRandomInt(9, 999) + ".png";
            if (!this.imgs.includes(img)) {
                this.imgs.push(img);
            } else {
                --i;
            }
        }
    }
}
