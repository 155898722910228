
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
    name: "Description",
})
export default class Description extends Vue {
    $assetsPrefix!: any;

    currentImage = this.$assetsPrefix + "/buildings/RACETRACK.png";

    images = [
        "/buildings/RACETRACK.png",
        "/buildings/GuineAcademy.png",
        "/buildings/GuineaPods.png",
        "/buildings/hall\ of\ Fame.png",
        "/buildings/BANK.png",
    ];

    index = 0;

    beforeMount() {
        setInterval(() => {
            ++this.index;

            if (this.index === this.images.length) {
                this.index = 0;
            }

            this.currentImage = this.$assetsPrefix + this.images[this.index];
        }, 3000);
    }
}
