
import {Component, Vue, Prop} from 'vue-property-decorator';
import {KinesisContainer, KinesisElement} from "vue-kinesis";
import Preview from "@/components/Preview.vue";

@Component({
    name: "TopPage",
    components: {Preview, KinesisContainer, KinesisElement}
})
export default class TopPage extends Vue {
    @Prop() race!: boolean;

    currentIndex1 = 0;
    currentIndex2 = 0;
    currentIndex3 = 0;
    images: string[][] = [
        [
            "sup1/ddddd",
        ],
        [
            "sup1/footbaall_r",
            "sup1/carrot_grey_r",
        ],
        [
            "sup1/vr_pipe",
            "sup1/monocle",
            "sup1/cats",
            "sup1/angel_robot",
        ]
    ];

    scrollToBottom() {
        const layer: Element | null = document.body.querySelector('#topPage + div + div');

        if (layer) {
            this.$scrollTo(layer, 700);
        }
    }

    goToMint() {
        window.open("https://guineapigsclub-mint.vercel.app/", "_blank");
    }

    beforeMount() {
        // this.images.forEach((iarr:string[], i:number) => {
        //     setInterval(() => {
        //         let index:string = 'currentIndex' + (i + 1);
        //         // @ts-ignore;
        //         ++this[index];
        //         // @ts-ignore;
        //         this[index] = this[index] === this.images[i].length ? 0 : this[index];
        //     }, 5000);
        // });
    }
}
